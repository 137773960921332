var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sideslip-image-dynamic',
    'sideslip-image-slider',
    _vm.isNeedCardBg ? 'has-padding' : '',
    _vm.intervalSpacing == '1' ? 'has-item-space' : '',
  ],style:([_vm.cardImgRadiusStyle(), { padding: _vm.padding }])},[_vm._l((_vm.data),function(item,i){return [(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getAnalysisData('2-22-2', { item, index: i, useBffApi: true,})),expression:"getAnalysisData('2-22-2', { item, index: i, useBffApi: true,})"},{name:"expose",rawName:"v-expose",value:(_vm.getAnalysisData('2-22-1', { item, index: i, useBffApi: true,})),expression:"getAnalysisData('2-22-1', { item, index: i, useBffApi: true,})"}],key:i,class:[`sideslip-image-slider__item-${_vm.type}`],style:([_vm.cardImgRadiusStyle(i), { overflow: 'hidden' }]),attrs:{"brand":_vm.brand,"placeholder":{
        width: item.image.width,
        height: item.image.height,
      },"special-radius":_vm.intervalSpacing == '1' ? _vm.cardImgRadiusStyle(i)?.borderRadius : '',"ratio":item.image.ratio,"img-src":item.image.src,"img-design-width":216,"ada":item.ada,"first-screen":_vm.isFirstPage && i < _vm.ssrCount,"comp-src":_vm.compSrc},on:{"click":(event) => _vm.clickItem(event, item, i),"baseImageMounted":function($event){return _vm.baseImageMounted()}}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }