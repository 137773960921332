<template>
  <div
    v-if="viewList.length && isCardStyle"
    ref="sideslipImageRef"
    class="sideslip"
    :class="{
      'sideslip__card-bg': isNeedCardBg,
    }"
    :style="[bgStyle, cardStyle, !isNeedCardBg && !isCarouselImage ? { margin: 0, borderRadius: 0 } : {}]"
  >
    <!-- 轮播图 -->
    <template
      v-if="isCarouselImage"
    >
      <CarouselImage
        :prop-data="propData"
        :index="index"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :card-style="cardStyle"
        :brand="brand"
        class="not-fsp-element"
        swiper-inject-style=".swiper-pagination .swiper-pagination-bullet-active{
          width: 10px;
          height: 4px;
          background: rgba(255, 255, 255, 0.8);
          border: 1px solid #bbb;
          border-radius: 6px;
        }"
      />
    </template>
    <template
      v-if="isSideSlipImage"
    >
      <!-- 静态 2.5 3.5 4.5 图标题 start-->
      <div
        v-if="(isTwoPointFiveImage || isThreePointFiveImage || isFourPointFiveImage) && showFloorTitle"
        class="sideslip__title"
        :class="{
          'sideslip__picTitle': floorTitleType === 'image',
          'sideslip__picTitle_left': floorTitlePosition === 'left',
          'sideslip__picTitle_center': floorTitlePosition === 'center',
          'sideslip__picTitle_right': floorTitlePosition === 'right',
          'sideslip__picTitle_4-5': isFourPointFiveImage,
        }"
        :style="{
          textAlign: cssRight && floorTitlePosition === 'left' ? 'right' : floorTitlePosition,
          color: floorTitleColor,
        }"
      >
        <span v-if="floorTitleType === 'text'">{{ floorTitle }}</span>
        <BaseImg
          v-if="floorTitleType === 'image'"
          fit="contain"
          style="height: 20px;padding: auto;"
          :img-src="titleImage.src"
          :class="floorTitlePosition === 'center' ? 'sideslip__title_center-img' : ''"
          :first-screen="propData?.isFirstPage"
          :comp-src="'sideslip-image-dynamic'"
        />
        <BaseImg
          v-if="isFourPointFiveImage && floorTitleType === 'text' && titleLogo?.src"
          fit="contain"
          :style="[titleLogoSize]"
          :img-src="titleLogo?.src"
          :placeholder="{
            width: getTitleLogoWidth(),
            height: 16
          }"
          :ratio="titleLogo.ratio"
          :position="cssRight ? '100% 50%' : '0% 50%'"
          class="not-fsp-element header__big-sale"
          :img-design-width="getTitleLogoWidth() * 3"
          :first-screen="propData?.isFirstPage"
        />
      </div>
      <!-- 静态 3.5 图标题 end-->

      <div
        v-if="dynamicCompStyleType.includes(propData.styleType) && titleStyle.titleText"
        class="sideslip__title"
        :style="{
          textAlign: cssRight && titleStyle.titlePosition === 'left' ? 'right' : titleStyle.titlePosition,
          color: titleStyle.titleColor,
        }"
      >
        {{ titleStyle.titleText }}
      </div>

      <!-- 整屏横滑 顶部标题 -->
      <SideSlipImage
        :data="viewList"
        :prop-data="propData"
        :index="index"
        :scene-data="sceneData"
        :cate-links="cateLinks"
        :is-need-card-bg="isNeedCardBg"
        :brand="brand"
        :extend-data="extendData"
        :is-css-right="cssRight"
        :padding="!isNeedCardBg ? cardStyle?.margin : ''"
        :comp-src="'sideslip-image-dynamic'"
        @baseImageMounted=" baseImageMounted()"
      />
    </template>
  </div>
  <!-- 切换静态组件 -->
  <SideSlipImageStatic
    v-else
    v-bind="$props"
    @baseImageMounted="baseImageMounted()"
  />
</template>

<script>
import SideSlipImageStatic from 'public/src/pages/components/ccc/components/sideslip-image/Index.vue'
import SideSlipImage from './SideSlipImageSlide.vue'
import CarouselImage from '../../components/CarouselImage.vue'

import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixin from '../../components/mixins/mixin'
import BaseImg from '../../base/BaseImg'

const staticCompStyleType = [
  'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE', // 静态3.5图
  'ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE', // 静态2.5图
  'IMAGE_CAROUSEL_COMPONENT', // 静态轮播图
  'ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE', // 静态4.5图
]
const dynamicCompStyleType = [
  'MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC', // 动态3.5图
  'IMAGE_CAROUSEL_DYNAMIC', // 动态轮播图
]

const swiperDynamicMap = [
  ...staticCompStyleType,
]

export default {
  name: 'SideSlipCompose',
  components: {
    SideSlipImage,
    SideSlipImageStatic,
    CarouselImage,
    BaseImg
  },
  mixins: [mixin],
  props: {
    ...commonProps,
    // 静态横滑组件是否命中卡片化 abt
    isCardAbt: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      isFirstPage: this.propData?.isFirstPage,
    }
  },
  data() {
    let {
      props: {
        metaData: {
          tabSelectedColor = '#222222',
          tabSelectColor = '#767676',
          tabBgColor = 'white',
          showConvertScreenTab = '0',
          titleColor = '#222222',
          titlePosition = 'left',
          titleText = '',
          // 静态 3.5 图标题配置
          showFloorTitle = '0',
          isCardShow = '0',
          floorTitleType = 'text', // 标题类型 text || image
          floorTitle = '',
          floorTitlePosition = 'left', // 标题位置 left || center
          floorTitleColor = '',
          titleImage = {},
          titleLogo,
          bigSaleLogo,
          bgFillType,
          backgroundImg = {},
          backgroundColor = ''
        },
      },
    } = this.propData || {}
    const extendData = this.sceneData?.extendData || this.context?.content?.extendParse || {}
    if (bigSaleLogo?.src) {
      const { height, width } = bigSaleLogo || {}
      bigSaleLogo.ratio =  (width / height) || 1
      titleLogo = bigSaleLogo // 大促logo优先展示
    }
    return {
      showFloorTitle: showFloorTitle == '1',
      isCardShow,
      floorTitleType,
      floorTitle,
      floorTitlePosition,
      floorTitleColor,
      titleImage,

      isTabMode: showConvertScreenTab == '1', // Tab模式
      titleStyle: {
        titleColor,
        titlePosition,
        titleText: titleText || floorTitle,
      },
      tabTextStyle: {
        selectedColor: tabSelectedColor,
        unSelectedColor: tabSelectColor,
        bgColor: tabBgColor,
      },
      // 初始Swiper配置
      options: {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 8,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      },
      
      // swiperIndex从0开始
      swiperIndex: 0,
      // selectedTabIndex从1开始
      selectedTabIndex: 1,
      extendData, // 页面全局配置
      dynamicCompStyleType,
      staticCompStyleType,
      titleLogo,
      bgFillType,
      backgroundImg,
      backgroundColor,
    }
  },
  computed: {
    bgStyle() {
      // 横滑4.5图 走皮肤需求新增的字段 卡片化&&有标题 才生效
      if(this.isFourPointFiveImage && this.isCardShow == 1 && this.showFloorTitle == 1){
        return this.bgFillType == 1 ? { backgroundColor: this.backgroundColor } : { backgroundImage: `url(${this.backgroundImg?.src})`, backgroundSize: '100% 100%' }
      }
      return {}
    },
    cssRight() {
      return this.context?.GB_cssRight
    },
    isFourPointFiveImage() {
      return this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE'
    },
    isThreePointFiveImage() {
      return this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE'
    },
    isTwoPointFiveImage() {
      return this.propData?.styleType === 'ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE'
    },
    // 静态/动态轮播图卡片化
    isCarouselImage() {
      return (
        this.propData.styleType === 'IMAGE_CAROUSEL_DYNAMIC' ||
        this.propData.styleType === 'IMAGE_CAROUSEL_COMPONENT'
      )
    },
    // 静态/动态3.5图、静态2.5图卡片化、静态4.5
    isSideSlipImage() {
      return (
        this.propData.styleType === 'MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC' ||
        this.propData.styleType === 'ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE' ||
        this.propData.styleType === 'ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE' ||
        this.propData.styleType === 'ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE'
      )
    },
    // 静态组件卡片化通过 abt 控制
    isStaticCardStyle() {
      return this.isCardAbt 
    },
    isCardStyle() {
      if (staticCompStyleType.includes(this.propData.styleType)) {
        return this.isStaticCardStyle
      }
      if (dynamicCompStyleType.includes(this.propData.styleType)) {
        return this.isDynamicCardStyle
      }

      return false
    },
    viewList() {
      return this.propData.props.items
    },
    // 动态组件卡片组件接全局配置: 卡片化字段为false，或者边距、圆角 这2项都获取失败或为空时，代表非卡片化样式，展示原有样式
    isDynamicCardStyle() {
      const {
        isCardShow = '0',
        margin: marginList = [],
        cardRadius = 0,
      } = this.propData?.props?.metaData || {}
      if(!isCardShow) return false
      if (isCardShow || cardRadius > 0) return true
      return Array.isArray(marginList) && marginList.some((item) => item > 0)
    },
    // 配置标题、tab、文本相关则需要卡片背景
    isNeedCardBg() {
      // slide和swiper的viewList结构区分
      if (!swiperDynamicMap.includes(this.propData.styleType)) return false
      const hadSmallTitle = this.viewList.some((item) => {
        if (!Array.isArray(item?.items)) return false
        return item?.items.some((i) => i.smallTitle)
      })
      return Boolean(
        this.isTabMode 
        || hadSmallTitle 
        || this.titleStyle?.titleText 
        || this.showFloorTitle
      )
    },
    cardStyle() {
      // 1.如果全局配置中有卡片化配置，则动态组件使用全局配置中的卡片化配置
      // 2.如果全局配置中有卡片化配置，则静态组件命中abt时使用全局配置中的卡片化配置
      // 3.如果全局配置中没有卡片化配置，则静态组件命中abt时使用默认的兜底卡片化配置 cardRadius=3, margin=[0, 8, 10, 8]

      // 静态组件
      if (staticCompStyleType.includes(this.propData.styleType)) {
        const defaultMargin = [0, 8, 10, 8]
        const defaultCardRadius = 3

        const { isCardShow = false, margin = [], cardRadius = 0 } = this.propData?.props?.metaData || {}
        const marginList =
          isCardShow && Array.isArray(margin) && margin.length === 4
            ? margin.slice()
            : defaultMargin
        const marginStyle = marginList
          .map((item) => `${Number(item)}px`)
          .join(' ')
        const styleResult = {
          margin: marginStyle,
          borderRadius: isCardShow ? `${cardRadius}px` : `${defaultCardRadius}px`,
        }
        // 静态 2.5 图卡片化右侧贴边,右侧不需要圆角,没有卡片底衬，不需要 margin
        if (this.isTwoPointFiveImage) {
          if (!this.cssRight) {
            styleResult.borderRadius = isCardShow ? `${cardRadius}px 0 0 ${cardRadius}px` : `${defaultCardRadius}px 0 0 ${defaultCardRadius}px`
          } else {
            styleResult.borderRadius = isCardShow ? `0 ${cardRadius}px ${cardRadius}px 0` : `0 ${defaultCardRadius}px ${defaultCardRadius}px 0`
          }
        }
        // 静态 3.5 图片卡片化且无标题时右侧贴边,右侧不需要圆角
        if (this.isThreePointFiveImage && !this.showFloorTitle) {
          if (!this.cssRight) {
            styleResult.marginRight = 0
            styleResult.borderRadius = isCardShow ? `${cardRadius}px 0 0 ${cardRadius}px` : `${defaultCardRadius}px 0 0 ${defaultCardRadius}px`
          } else {
            styleResult.marginLeft = 0
            styleResult.borderRadius = isCardShow ? `0 ${cardRadius}px ${cardRadius}px 0` : `0 ${defaultCardRadius}px ${defaultCardRadius}px 0`
          }
        }
        return styleResult
      }

      // 动态组件
      if (dynamicCompStyleType.includes(this.propData.styleType)) {
        const { margin = [], cardRadius = 0 } = this.propData?.props?.metaData || {}
        const marginList =
          Array.isArray(margin) && margin.length === 4
            ? margin.slice()
            : Array(4).fill(0)
        const marginStyle = marginList
          .map((item) => `${Number(item)}px`)
          .join(' ')
        const styleResult = {
          margin: marginStyle,
          borderRadius: cardRadius ? `${cardRadius}px` : '',
        }
        return styleResult
      }
      
      return {}
    },
    // logo大小
    titleLogoSize() {
      return {
        width: this.getTitleLogoWidth() + 'px',
        height: '16px',
      }
    },
  },
  mounted() {
    this.handleSlslog()
  },
  methods: {
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    handleSwiperEnd() {
      const { GB_cssRight } = gbCommonInfo
      let activeIndex =
        this.$refs.cccSwiperInstance?.$el.swiper.activeIndex ?? 0

      this.selectedTabIndex = activeIndex + 1
      this.swiperIndex = GB_cssRight ? -activeIndex : activeIndex
    },
    handleSlslog() {
      if(this.isCardStyle) {
        // 无数据异常上报
        if(!this.viewList.length) {
          this.hideCompSls({
            logLevel: 3,
            tag: 'web_client_home_error_total',
            message: 'CCCShownFilterError',
            reason: 'itemsEmpty',
          })
        } else {
          this.viewList.map(viewItem => {
            // 图片为空异常上报
            if(!viewItem?.image?.src) {
              this.hideCompSls({
                logLevel: 3,
                tag: 'web_client_home_error_total',
                message: 'CCCDataError',
                reason: 'itemImageError',
              })
            }
          })
        }
      }
    },
    getTitleLogoWidth() {
      return 16 * Number(this.titleLogo?.ratio) + 1 // 适当增加1px的宽度，避免图片显示不全
    },
  },
}
</script>

<style lang="less" scoped>
.sideslip {
  overflow: hidden;

  &__pagination {
    padding: 4px 0 0;
  }

  &__pagination-area {
    margin: 0 auto;
    width: 24px;
    position: relative;
    background-color: #e5e5e5;
    overflow: hidden;
  }

  &__pagination-active {
    display: block;
    height: 3px;
    width: 12px;
    background-color: #222;
    transition: transform 0.2s;
  }

  .sideslip-placeholder {
    width: 100%;
    height: 12px;
  }

  &__card-bg {
    padding: 4px 0;
    background-color: #fff;

    .sideslip__tab {
      margin-top: -4px;
    }

    .sideslip__slide {
      padding: 0 4px;
    }
    
    .sideslip__title {
      margin: 4px 0 4px;
      padding: 0 6px;
      width: 100%;
      font-size: 13px;
      line-height: 16px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      
      /deep/ .base-img__inner {
        width:auto;
      }
      &_center-img {
        /deep/ .base-img__inner {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .sideslip__picTitle {
      margin-top: 2px;
    }
  }
  .sideslip__picTitle_left { 
    justify-content: flex-start;
  }
  .sideslip__picTitle_center { 
    justify-content: center;
  }
  .sideslip__picTitle_right { 
    justify-content: flex-end;
    padding-right: 4px;
  }
  .sideslip__picTitle_4-5 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 16px;
    margin-bottom: 4px;
    span {
      flex-shrink: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      max-width: 100%;
      height: 16px;
      line-height: 16px;
    }
    .header__big-sale {
      margin-left: 4px;
    }
  }
  /deep/ .base-img__href {
    margin-bottom: -1px;  // 1px以防露出间隙、黑边
    .base-img__inner {
      width: auto !important;
    }
  }
  /deep/ .ccc-swiper-pagination__item-active {
    width: 20/75rem;
    height: 8/75rem;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #bbb;
    border-radius: 8/75rem;
  }
}
</style>
