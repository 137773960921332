var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.viewList.length && _vm.isCardStyle)?_c('div',{ref:"sideslipImageRef",staticClass:"sideslip",class:{
    'sideslip__card-bg': _vm.isNeedCardBg,
  },style:([_vm.bgStyle, _vm.cardStyle, !_vm.isNeedCardBg && !_vm.isCarouselImage ? { margin: 0, borderRadius: 0 } : {}])},[(_vm.isCarouselImage)?[_c('CarouselImage',{staticClass:"not-fsp-element",attrs:{"prop-data":_vm.propData,"index":_vm.index,"scene-data":_vm.sceneData,"cate-links":_vm.cateLinks,"card-style":_vm.cardStyle,"brand":_vm.brand,"swiper-inject-style":".swiper-pagination .swiper-pagination-bullet-active{\n        width: 10px;\n        height: 4px;\n        background: rgba(255, 255, 255, 0.8);\n        border: 1px solid #bbb;\n        border-radius: 6px;\n      }"}})]:_vm._e(),_vm._v(" "),(_vm.isSideSlipImage)?[((_vm.isTwoPointFiveImage || _vm.isThreePointFiveImage || _vm.isFourPointFiveImage) && _vm.showFloorTitle)?_c('div',{staticClass:"sideslip__title",class:{
        'sideslip__picTitle': _vm.floorTitleType === 'image',
        'sideslip__picTitle_left': _vm.floorTitlePosition === 'left',
        'sideslip__picTitle_center': _vm.floorTitlePosition === 'center',
        'sideslip__picTitle_right': _vm.floorTitlePosition === 'right',
        'sideslip__picTitle_4-5': _vm.isFourPointFiveImage,
      },style:({
        textAlign: _vm.cssRight && _vm.floorTitlePosition === 'left' ? 'right' : _vm.floorTitlePosition,
        color: _vm.floorTitleColor,
      })},[(_vm.floorTitleType === 'text')?_c('span',[_vm._v(_vm._s(_vm.floorTitle))]):_vm._e(),_vm._v(" "),(_vm.floorTitleType === 'image')?_c('BaseImg',{class:_vm.floorTitlePosition === 'center' ? 'sideslip__title_center-img' : '',staticStyle:{"height":"20px","padding":"auto"},attrs:{"fit":"contain","img-src":_vm.titleImage.src,"first-screen":_vm.propData?.isFirstPage,"comp-src":'sideslip-image-dynamic'}}):_vm._e(),_vm._v(" "),(_vm.isFourPointFiveImage && _vm.floorTitleType === 'text' && _vm.titleLogo?.src)?_c('BaseImg',{staticClass:"not-fsp-element header__big-sale",style:([_vm.titleLogoSize]),attrs:{"fit":"contain","img-src":_vm.titleLogo?.src,"placeholder":{
          width: _vm.getTitleLogoWidth(),
          height: 16
        },"ratio":_vm.titleLogo.ratio,"position":_vm.cssRight ? '100% 50%' : '0% 50%',"img-design-width":_vm.getTitleLogoWidth() * 3,"first-screen":_vm.propData?.isFirstPage}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.dynamicCompStyleType.includes(_vm.propData.styleType) && _vm.titleStyle.titleText)?_c('div',{staticClass:"sideslip__title",style:({
        textAlign: _vm.cssRight && _vm.titleStyle.titlePosition === 'left' ? 'right' : _vm.titleStyle.titlePosition,
        color: _vm.titleStyle.titleColor,
      })},[_vm._v("\n      "+_vm._s(_vm.titleStyle.titleText)+"\n    ")]):_vm._e(),_vm._v(" "),_c('SideSlipImage',{attrs:{"data":_vm.viewList,"prop-data":_vm.propData,"index":_vm.index,"scene-data":_vm.sceneData,"cate-links":_vm.cateLinks,"is-need-card-bg":_vm.isNeedCardBg,"brand":_vm.brand,"extend-data":_vm.extendData,"is-css-right":_vm.cssRight,"padding":!_vm.isNeedCardBg ? _vm.cardStyle?.margin : '',"comp-src":'sideslip-image-dynamic'},on:{"baseImageMounted":function($event){return _vm.baseImageMounted()}}})]:_vm._e()],2):_c('SideSlipImageStatic',_vm._b({on:{"baseImageMounted":function($event){return _vm.baseImageMounted()}}},'SideSlipImageStatic',_vm.$props,false))
}
var staticRenderFns = []

export { render, staticRenderFns }